import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { hasString } from 'src/helpers/string';
import { debounce, isArray, isNil } from 'lodash';
import { fetchEntities } from 'src/-utils/model';
import AsyncSelect from 'react-select/async'
import i18n from 'src/i18n';
import { ValueType } from 'react-select';
import { PortalJsonApiEntity, PortalRecord } from 'src/-types/models/portal';
import { hasArray } from 'src/helpers/array';

interface PortalPickerProps {
  label: string | undefined;
  isMulti: boolean | undefined;
  name: string;
  value: PortalJsonApiEntity | PortalJsonApiEntity[] | undefined;
  onChange: (records: PortalJsonApiEntity | PortalJsonApiEntity[]) => void;
  mustShowDebug: boolean | undefined;
  errors: any | undefined;
}

export const PortalPicker: React.FunctionComponent<PortalPickerProps> = ({ label = undefined, isMulti = false, name, onChange, value = undefined, mustShowDebug = false, errors = undefined }) => {
  const [defaultOptions, setDefaultOptions] = useState<PortalJsonApiEntity[]>([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const getOptionLabel = useCallback((e: any) => e.attributes.displayText, []);
  const getOptionValue = useCallback((e: any) => e.id, []);
  const loadOptions = useCallback((filter: string, callback: any) => {
    (async () => {
      const locations = await fetchEntities<PortalRecord>('portals', { filter, locale: i18n.language });

      callback(locations.data);
    })();
  }, []);
  const debouncedLoadOptions = useMemo(() => debounce(loadOptions, 250), [loadOptions]);
  const containerClassName = useMemo(() => ['form-group'].join(' '), []);
  const _onChange = useCallback((value: ValueType<PortalJsonApiEntity>) => {
    if (isArray(value)) {
      onChange(value as PortalJsonApiEntity[]);
    } else if (!isNil(value)) {
      onChange(value as PortalJsonApiEntity);
    }
  }, [onChange]);
  const errorMessage = useMemo(() => errors?.join(', ') ?? null, [errors]);
  const selectClassName = useMemo(() => {
    const classNamesSelect = ['basic-single'];

    if (isArray(errors)) {
      classNamesSelect.push('is-invalid');
    }

    const result = classNamesSelect.join(' ');

    return result;
  }, [errors]);

  useEffect(() => {
    (async () => {
      setIsDisabled(true);

      const locations = await fetchEntities<PortalRecord>('portals', { locale: i18n.language });

      setIsDisabled(false);
      setDefaultOptions(locations.data);
    })();
  }, []);

  return (
    <div className={containerClassName}>
      {hasString(label) && <label className="form-label">{label}</label>}
      <AsyncSelect
        className={selectClassName}
        classNamePrefix={`select-with-search-prefix`}
        defaultOptions={defaultOptions}
        defaultValue={value}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        isDisabled={isDisabled}
        isMulti={isMulti}
        loadOptions={debouncedLoadOptions}
        name={name}
        onChange={_onChange}
      />
      {mustShowDebug && <code>{JSON.stringify(errors)}</code>}
      {hasArray(errors) && hasString(errorMessage) && <div className='invalid-feedback d-block'>{errorMessage}</div>}
    </div>
  );
};
