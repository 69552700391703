import React from 'react';
import { camelCase } from 'lodash';

import VerticalDateInput from 'src/components/elements/form/VerticalDateInput';
import VerticalInput from 'src/components/elements/form/VerticalInput';

const componentByType = (type) => {
  switch (type) {
    case 'date':
      return VerticalDateInput;
    default:
      return VerticalInput;
  }
};

const Element = ({ field, inputs, name, onChanges, validations }) => {
  const Component = componentByType(field.fieldType);
  const onChange = onChanges[field.fieldType] || onChanges['text'];
  const fieldName = `specific.${name}`;
  const component = <Component
    value={inputs.specific && inputs.specific[name] ? inputs.specific[name] : ''}
    onChange={onChange}
    label={field.label}
    name={fieldName}
    errors={validations[name]}
  />;
  return (
    <>
      {!field.layoutOptionName && component}
      {field.layoutOptionName &&
        field.layoutOption.includes(inputs[camelCase(field.layoutOptionName)]) &&
        component
      }
    </>
  );
}

export default ({ fields, inputs, onChanges, validations }) => {
  const keys = Object.keys(fields);
  return (
    keys.map((key, index) =>
      <Element
        key={`${key}-${index}`}
        inputs={inputs}
        field={fields[key]}
        name={key}
        onChanges={onChanges}
        validations={validations}
      />
    )
  )
};

// required={required({ field })}
